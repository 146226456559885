import React from 'react';
import CustomImageSlider from '../components/customImageSlider';
import CustomOffers from '../components/customOffers';
import TrackLink from '../components/trackLink';
import Layout from '../components/layout';
import { Column, Grid } from '../components/styleBook';

const InformationPage = () => {
  return (
    <Layout>
      <section className="information">
        <h1 id="timeimage">Timeimage Oy</h1>

        <CustomImageSlider />

        <Grid>
          <Column>
            <p>
              Vuonna 2003 perustettu Timeimage Oy huoltaa ja vuokraa
              tietoteknisiä ja audiovisuaalisia laitteita yritysten ja
              yksityishenkilöiden tarpeisiin. Palveluita tarjotaan joustavasti
              ja ammattitaitoisesti tilanteeseen sopivalla tavalla.
            </p>

            <p>
              Meillä on lähes 20 vuoden kokemus pk-yritysten tietoteknisen
              infrastruktuurin suunnittelusta, rakentamisesta ja ylläpidosta.
              Tarjoamme avaimet käteen -periaatteella koko infrastruktuurin
              rakentamisen aina lähiverkon kaapelinvedosta palvelimien
              konfigurointiin.
            </p>

            <p>
              Korjaamme ja annamme kustannusarvioita tietokoneista,
              ATK-laitteista ja videotykeistä.
            </p>
          </Column>

          <Column>
            <p>
              <TrackLink to="vuokraus">Vuokraamme</TrackLink> edullisesti
              seuraavia laitteita:
            </p>

            <ul>
              <li>Videotykit ja valkokankaat</li>
              <li>Kannettavat tietokoneet</li>
            </ul>

            <p>
              Ota yhteyttä numeroon 0500 434 040 ja etsimme sinulle sopivan
              laitekokoonpanon sekä sovimme laitteiden toimituksesta tai
              noudosta.
            </p>
          </Column>
        </Grid>

        <CustomOffers />
      </section>
    </Layout>
  );
};

export default InformationPage;
