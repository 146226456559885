import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Column, Grid } from './styleBook';
import styled from 'styled-components';

const CustomOffersGrid = styled(Grid)`
  margin-top: 10px;
`;

const CustomOffers = () => {
  return (
    <div>
      <h1>Ajankohtaista</h1>
      <CustomOffersGrid>
        <Column>
          <h2>Myymme vähänkäytettynä</h2>
          <ul>
            <li>Tietokoneen komponentit</li>
            <li>Verkkolaitteet</li>
            <li>Kannettavat tietokoneet</li>
            <li>Työasemat (HP, Lenovo, Dell, yms.)</li>
            <li>Räätälöidyt peli-PC:t ja tehotyöasemat</li>
            <li>Tulostimet</li>
          </ul>
          Katso ajankohtaiset{' '}
          <OutboundLink href="https://www.timeimage.fi/verkkokauppa/kaytetyt">
            tuotteet
          </OutboundLink>
        </Column>

        <Column>
          <h2>Tarjous 20.10.2017 alkaen</h2>
          <p>Tietokoneen täysi tarkistus 52&euro; (sis. alv.).</p>

          <strong>Tarkistukseen sisältyy:</strong>
          <ul>
            <li>Tietokoneen yleiskunto</li>
            <li>Ohjelmapäivitykset</li>
            <li>Laitteiston kunto</li>
            <li>Raportti em. asioista</li>
          </ul>
        </Column>
      </CustomOffersGrid>
    </div>
  );
};

export default CustomOffers;
