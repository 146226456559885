import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoint } from './styleBook';

const SliderContainer = styled.div`
  .slider-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .slider-button {
    font-size: 16px;
    border: none;
    background-color: transparent;
    padding: 0;
    color: coral;
    margin: 0 24px;
  }

  .slider-button em {
    font-size: 24px;
  }

  .slider-button:hover {
    cursor: pointer;
  }

  @media ${breakpoint.desktop} {
    img {
      max-width: 500px;
      max-height: 280px;
    }
  }

  @media ${breakpoint.mobile} {
    img {
      max-width: 100%;
      max-height: 280px;
    }
  }

  text-align: center;
  height: 100%;
  margin-bottom: 10px;

  a {
    font-size: 30px;
    text-decoration: none;
    vertical-align: middle;
  }
`;

const ads = ['/images/ups_mainos1.jpg', '/images/faceb_kevyt1.jpg'];

const CustomImageSlider = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handlePrevious = () => {
    let newSelectedIndex = selectedIndex - 1;

    if (newSelectedIndex < 0) {
      newSelectedIndex = ads.length - 1;
    }

    setSelectedIndex(newSelectedIndex);
  };

  const handleNext = () => {
    let newSelectedIndex = selectedIndex + 1;

    if (newSelectedIndex >= ads.length) {
      newSelectedIndex = 0;
    }

    setSelectedIndex(newSelectedIndex);
  };

  return (
    <SliderContainer>
      <img alt="placeholder" src={ads[selectedIndex]} />

      <div className="slider-buttons">
        <button className="prev slider-button" onClick={handlePrevious}>
          <em>&laquo;</em> Edellinen
        </button>

        <button className="next slider-button" onClick={handleNext}>
          Seuraava <em>&raquo;</em>
        </button>
      </div>
    </SliderContainer>
  );
};

export default CustomImageSlider;
